.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
;}

.downloadIcon{
	text-align: right;
	padding-right: 20px;
}
