.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
}

.bulkRemoveIcon {
	float: right;
	right: 6px;
	top: 6px;
};

.downloadIcon{
	text-align: right;
	padding-right: 20px;
}
