.link {
	text-decoration: none;
}

#header {
	line-height: initial;
	display: flex;
	padding: 6px;
	padding: 2px 6px 4px 6px;
	font-size: 11px;

}

.linkRoot {
}

#divider {
	margin-bottom: 12px;
}

.listItemIcon {
	margin-left: 5px;
	min-width: initial !important;
}

