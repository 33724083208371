.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
	;
}

.searchBox {
	background: #fff !important;
	border-radius: 10px !important;
	padding: 15px 10px !important;
	-webkit-box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
	-moz-box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
	box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
}

.searchBoxWrapper {
	position: relative;

	&>div {
		width: 100%;
	}

	& .findBtn {
		position: absolute;
		right: 0px;
		border-radius: 0 10px 10px 0;
		height: 53px;
		width: 120px;
	}
	& ul {
		margin-top: 20px;
		position: absolute;
		width: 70%;
		float: right;
		border-radius: 10px;
		right: 0;
		z-index: 99;
		-webkit-box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
		-moz-box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
		box-shadow: 0px 0px 7px 4px rgba(211, 211, 211, 1);
	}
}

.highlight {
	border: 2px dashed red;
	padding: 0.03em 0.25em;
}