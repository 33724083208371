.link {
	text-decoration: none;

}

#header {
	line-height: initial;
	display: flex;
	padding: 0px 6px;
}

.box {
	background-color: white;
	height: 160px;
	width: 160px;
	display: flex;
	position: relative;
	justify-content: center;

	.link {
		flex: 1
	}
}

#divider {
	margin-bottom: 12px;
}

.listItemIcon {
	margin-left: 5px;

	min-width: initial !important;
	color: #3f51b5 !important;
}

.label {
	position: absolute;
	bottom: 6%;
	font-weight: bold;
	color: #5514b4;
	font-family: 'Bt Curve';
}
