@import './fonts.scss';


.grow {
	flex: 1;
}

html,
body,
#root {
	display: flex;
	flex-direction: column;
	height: 100%;
	flex: 1
}

#root-container {
	display: flex;
	flex-direction: row;
	height: 100%;
	flex: 1;
}

// Styles to make links nested in fab buttons clickable in all areas of the fab project-wide
span.MuiFab-label {
	height: 100%;

	a {
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 100%;
	}
}

.speedDial {
		bottom: 12px;
		position: fixed;
		right: 12px;
}
