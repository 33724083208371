.grow {
	flex: 1;
}

html,
body,
#root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

// Styles to make links nested in fab buttons clickable in all areas of the fab project-wide
span.MuiFab-label {
	height: 100%;

	a {
		align-items: center;
		height: 100%;
		justify-content: center;
		width: 100%;
	}
}
