.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
}

.statusStyleSuccess {
	color: #4cc26b;
}

.statusStyleSuccess > svg {
	vertical-align: middle;
	color: #4cc26b;
	font-size: medium;
}

.statusStyleFailed {
	color: red;
}

.statusStyleFailed > svg {
	vertical-align: middle;
	color: red;
	font-size: medium;
}
.downloadIcon{
	text-align: right;
	padding-right: 20px;
}