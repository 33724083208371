.container {
	background-color: white;

}

.containerLoader {
	display: flex;
	margin: 24px 0px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.listItemText {
	white-space: pre;
}
