.container {
	flex: 1;
	padding: 12px;
	font-family: inherit;
}


.container div {
	justify-content: center;
;}


.containerRowActions {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.buttonRowAction {
	border-radius: 60px;

}

.indicatorDot {
	display: flex;
	height: 16px;
	width: 16px;
	border-radius: 60px;
	opacity: 0.7;
}

.indicatorDotGreen {
	background-color: green;
}

.indicatorDotRed {
	background-color: red;
}

.containerRowStatus {
	display: flex;
	justify-content: center;
}
.downlodIconDeviceGroup{
	position: absolute;
	top: 6px;
	right: 6px;
}
.downlodIconDevice{
	display: block;
	text-align: right;
	padding-right: 20px;
	padding-bottom: 20px;
}