
@font-face {
	font-family: 'BT Curve';
	src: local('BT Curve'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_Rg.woff2)
	format('woff2');
	font-style: normal;
	font-display: swap;
	font-weight: normal;
}

@font-face {
	font-family: 'BT Curve';
	src: local('BT Curve'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_Bd.woff2)
	format('woff2');
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}

@font-face {
	font-family: 'BT Curve';
	src: local('BT Curve'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_BdIt.woff2)
	format('woff2');
	font-style: italic;
	font-display: swap;
	font-weight: bold;
}

@font-face {
	font-family: 'BT Curve';
	src: local('BT Curve'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_It.woff2)
	format('woff2');
	font-style: italic;
	font-display: swap;
	font-weight: normal;
}

@font-face {
	font-family: 'BT Curve Headline';
	src: local('BT Curve Headline'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_Headline.woff2)
	format('woff2');
	font-style: normal;
	font-display: swap;
	font-weight: 400;
}

@font-face {
	font-family: 'BT Curve Headline';
	src: local('BT Curve Headline'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web/WOFF2/BTCurve_W_HeadlineIt.woff2)
	format('woff2');
	font-style: italic;
	font-display: swap;
	font-weight: normal;
}

@font-face {
	font-family: 'BT Curve Condensed';
	src: local('BT Curve Condensed'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web_Condensed/WOFF2/BTCurveCd_W_Bd.woff2)
	format('woff2');
	font-style: normal;
	font-display: swap;
	font-weight: bold;
}

@font-face {
	font-family: 'BT Curve Condensed';
	src: local('BT Curve Condensed'),
	url(./shared/fonts/BT_Curve_Web/BT_Curve_Web_Condensed/WOFF2/BTCurveCd_W_Rg.woff2)
	format('woff2');
	font-style: normal;
	font-display: swap;
	font-weight: normal;
}