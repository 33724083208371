.display_aw {
    display: block;
}
.hide_aw {
    display: none;
}
.disabledInput {
  pointer-events: none;
  opacity: 0.5;
  background-color:#f3f3f3;
  padding: 5px 5px 5px 5px;
}
.disabledInput > label {
  color: #000 !important;
}
.disabledInput > div:after {
  border-bottom: 0px solid #000 !important;
}

.disabledInput input{
  color: #f3f3f3;
}

.activationContainer {
  margin: 15px 23px 15px 23px;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
}
.activationHeading {
  font-weight: 800;
  margin: 25px 26px;
  color: #5514b4;
  font-family: "BT Curve Headline", "BT Curve", "Century Gothic", Arial, sans-serif;
}
.activationRows {
  margin: 30px 25px;
  border-radius: 8px;
  box-shadow: 2px 0px 4px 2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

}
.activationRows > * {
  margin: 15px !important;
  padding: 10px !important;
}
.activationRowMode {
  min-width: 120px;
}
.activationRowRemoveButton {
  padding: 5px 5px 3px 5px;
  background-color: #E4595B;
  color: #fff;
  border: 0px;
  margin-top: 40px;
  cursor: pointer;
}
.activationRowRemoveButton:hover {
  background-color: red;
}
.activationRowAddButton {
  padding: 5px;
  background-color: #4cc26b;
  color: #fff;
  border: 0px;
  cursor: pointer;
  margin: 5px 0px 15px 24px;
}
.activationRowAddButton:hover {
  background-color: green;
}
.activationRowAddButton > svg {
  vertical-align: middle;
}
.activationRowAddButtonText {
  vertical-align: middle;
  font-size: 16px;
  padding: 0 7px;
  font-weight: 800;
}
.maximumWindowMessage{
  margin: 0px 0px 20px 25px;
  color: #c7bdbd;
}

.error {
  color: #ffb0ab;
  font-style: italic;
  font-family: 'BT Curve','Century Gothic',Arial,sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.commonErrorContainer {
  padding-left: 30px;
}
