.link {
	text-decoration: none;
}

.drawer {
	overflow-x: hidden;
	overflow-y: hidden ;
	transition: 1s all;
	position: initial !important;
	width: 100%;
}

.root {
	display: flex;
	height: 100%;
}

.linkTextActive {
	font-weight: 600;
}

.linkText {
	text-align: center;
}


.buttonIconParent {
	text-align: right;
}

.buttonIconCollapse {
	align-self: flex-end;
}
