.container {
	margin-top: 12px;
}


.containerLoader {
	padding: 16px;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.cell {
	white-space: normal !important;
	word-break: break-word !important;
	white-space: pre-wrap !important;
	overflow-y: auto !important;
}

.listHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

